exports.components = {
  "component---content-404-mdx": () => import("./../../../content/404.mdx" /* webpackChunkName: "component---content-404-mdx" */),
  "component---content-beta-mdx": () => import("./../../../content/beta.mdx" /* webpackChunkName: "component---content-beta-mdx" */),
  "component---content-index-mdx": () => import("./../../../content/index.mdx" /* webpackChunkName: "component---content-index-mdx" */),
  "component---content-press-mdx": () => import("./../../../content/press.mdx" /* webpackChunkName: "component---content-press-mdx" */),
  "component---content-privacy-policy-mdx": () => import("./../../../content/privacy-policy.mdx" /* webpackChunkName: "component---content-privacy-policy-mdx" */),
  "component---content-terms-mdx": () => import("./../../../content/terms.mdx" /* webpackChunkName: "component---content-terms-mdx" */),
  "component---src-components-release-notes-tsx": () => import("./../../../src/components/ReleaseNotes.tsx" /* webpackChunkName: "component---src-components-release-notes-tsx" */)
}

